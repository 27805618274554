// StoreSection.jsx

import React, { useState } from 'react';
//import '../../styles/ContactUsForm/ContactSection.scss';

const StoreSection = () => {

  return (
    <>
        <section id="store-section" className="store-section">
            <h2>Store</h2>
        </section>
    </>
  );
}

export default StoreSection;