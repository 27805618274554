// SiliconNitrideWafers.jsx

import React from 'react';
import WafersTopBanner from './Products-Pages-components-templates/WafersTopBanner';
import WafersList from './Products-Pages-components-templates/WafersList';
import TypesOfSiliconWafer from './Products-Pages-components-templates/TypesOfSiliconWafer';
import WafersSection from './Products-Pages-components-templates/WafersSection';
import MajorApplicationsOfSiliconWafers from './Products-Pages-components-templates/MajorApplicationsOfSiliconWafers';
import OurCapability from './Products-Pages-components-templates/OurCapability';
import CustomSiliconWafer from './Products-Pages-components-templates/CustomSiliconWafer';
import SEMIStandardSpecifications from './Products-Pages-components-templates/SEMIStandardSpecifications';
//import '../styles/AboutUs/AboutUs.scss';

const SiliconNitrideWafers = () => {
  return (
    <>
      <div className="Silicon-Nitride-Wafers">
        <WafersTopBanner />
        <WafersSection />
        <TypesOfSiliconWafer />
        <MajorApplicationsOfSiliconWafers />
        <OurCapability />
        <CustomSiliconWafer />
        <WafersList />
        <SEMIStandardSpecifications />
      </div>
    </>
  );
};

export default SiliconNitrideWafers;