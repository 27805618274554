// YourWaferPartner.jsx

import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom'; // Import useLocation
import '../../styles/Products/YourWaferPartner.scss';

const YourWaferPartner = () => {

  const imageServerURL = process.env.REACT_APP_IMAGE_SERVER_URL;
  const BackendURL = process.env.REACT_APP_BACKEND_URL;
  
  const [partners, setPartners] = useState([]);
  let cmnPartner = 0;

  // Fetch Partners from the backend on component mount
  useEffect(() => {
    const fetchPartners = async () => {
      const url = `${BackendURL}/api/partners`; // API URL

      try {
        const response = await fetch(url);
        const result = await response.json();
        setPartners(result);
      } catch (error) {
        console.error('Error fetching Partners:', error);
      }
    };

    fetchPartners();
  }, []);

  return (
    <>
      <section id="your-wafer-partner" className="your-wafer-partner-section">
        <div className="your-wafer-partner-container">
          <div className="your-wafer-partner-background"></div>
        </div>
        <div className="your-wafer-partner">
          <div className="your-wafer-partner-text">
            <h1>We Work With the Best Partners</h1>
            <p>
              Phoenix Solutions can provide far more than just a silicon wafer.
              Today’s technology often requires the wafer to be a foundation for films and other processes.
              Phoenix Solutions can provide you many options in this regard. Do you have ongoing silicon needs?
              Would you like to get out of the business of filling out purchase orders and devote your time to running your operation?
              <br /><br />
              <NavLink to="/contactus" onClick={() => window.scrollTo(0, 0)} >Contact</NavLink> your Phoenix Solutions Sales Representative today!
            </p>
          </div>

          <div className="cmn-partner" style={{ '--quantity': partners.length + 1 }}>
            {partners.length > 0 ? (
              partners.map((partner, index) => {
                cmnPartner = index + 1;  // Storing index or other data
                return (
                  <div className={`cmn-card-pic cmn-card-pic-${index + 1}`} key={partner.id} style={{ '--position': index + 1 }}>
                    <img src={`${imageServerURL}${partner.image}`} alt={partner.name} />
                  </div>
                );
              })
            ) : (
              <p>No partners available.</p>
            )}
                <div className={`cmn-card-pic cmn-card-pic-${cmnPartner + 1} hidden-partner`} style={{ '--position': cmnPartner + 1 }}>
                  <h2>
                    OUR BEST PARTNERS
                  </h2>
                </div>
          </div>
          
          <div className="your-wafer-partner-text-2">
            <p>
              Phoenix Solutions can provide far more than just a silicon wafer.
              Today’s technology often requires the wafer to be a foundation for films and other processes.
              Phoenix Solutions can provide you many options in this regard. Do you have ongoing silicon needs?
              Would you like to get out of the business of filling out purchase orders and devote your time to running your operation?
              <br /><br />
              <NavLink to="/contactus" onClick={() => window.scrollTo(0, 0)} >Contact</NavLink> your Phoenix Solutions Sales Representative today!
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default YourWaferPartner;