//main1.jsx

import React from 'react';
import '../../styles/MainPages/main1.scss';

const main1 = () => {
  return (
    <>
      <div className="main1">
      <div>
        <h1> Phoenix Solution </h1>
      </div>
        <div class="sketchfab-embed-wrapper"> 
          <iframe title="Phoenix" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" 
                  allow="autoplay; fullscreen; xr-spatial-tracking" 
                  xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/2976e3a76d3a48278ffcae621f622901/embed"> 
          </iframe>
        </div>
      </div>
    </>
  );
};

export default main1;