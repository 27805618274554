// WorldwideSec.jsx

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import partten_vec from '../../images/Style/partten-vec.png.webp';
import map_1 from '../../images/Maps/map-1.png.webp';
import ship_icon_min from '../../images/Icon/ship-icon-min.svg';
import '../../styles/Home/WorldwideSec.scss';

const WorldwideSec = () => {
  return (
    <section className="worldwide-sec cmn-gap">
      <i className="w-top-pattern">
        <img
          width="265"
          height="433"
          src={partten_vec}
          alt="vector pattern"
        />
      </i>
      <div className="container">
        <div className="center-cont">
          <h2>We Ship Worldwide</h2>
          <p>From our warehouse in the US, we are able to ship to nearly any address in the world.</p>
        </div>
        <div className="ship-map-area">
          <img
            width="1112"
            height="651"
            src={map_1}
            alt="ship-worldwide"
            className="main-img"
          />
          <div className="shiping-area">
            <div className="ship-img">
              <img
                src={ship_icon_min}
                alt="ship-icon"
              />
            </div>
            <div className="ship-text">
              <h3>INTERNATIONAL SHIPPING</h3>
              <p>Now Shipping to over 50 countries.</p>
            </div>
          </div>
          {/* <div className="vertical-text-right">WORLDWIDE SHIPPING</div> */}
        </div>
        {/* Uncomment if you want to include the Contact Us section */}
          <div className="contact-us">
            <h2>Contact US</h2>
            <p>Silicon Phoenixsolution’s staff is comprised of well-trained professionals dedicated to providing you complete customer satisfaction.</p>
            <Link to="/contactus" onClick={() => window.scrollTo(0, 0)} >
              <button>Contact Us</button>
            </Link>
          </div>
      </div>
    </section>
  );
};

export default WorldwideSec;
