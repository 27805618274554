// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

// http-server D:\Phoenix_solution\Image\Product_Image

//App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/AboutUs';
import QuoteRequestForm from './pages/QuoteRequestForm';
import ContactUsForm from './pages/ContactUsForm';
import Products from './pages/Products';
import Store from './pages/Store';

import SiliconWafers from './components-templates/Products/ProductsPages/SiliconWafers/SiliconWafers';
import FusedZoneSilicaWafers from './components-templates/Products/ProductsPages/FusedZoneSilicaWafers/FusedZoneSilicaWafers';
import SiliconOnInsulatorWafers from './components-templates/Products/ProductsPages/SiliconOnInsulatorWafers/SiliconOnInsulatorWafers';
import SiliconThermalOxideWafers from './components-templates/Products/ProductsPages/SiliconThermalOxideWafers/SiliconThermalOxideWafers';
import SiliconNitrideWafers from './components-templates/Products/ProductsPages/SiliconNitrideWafers/SiliconNitrideWafers';
import FusedSilicaWafers from './components-templates/Products/ProductsPages/FusedSilicaWafers/FusedSilicaWafers';
import Borofloat33GlassWafers from './components-templates/Products/ProductsPages/Borofloat33GlassWafers/Borofloat33GlassWafers';
import SemiconductorMaterials from './components-templates/Products/ProductsPages/SemiconductorMaterials/SemiconductorMaterials';

import Main1 from './pages/MainPages/main1';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/about" element={<Layout><About /></Layout>} />
        <Route path="/quoterequest" element={<Layout><QuoteRequestForm /></Layout>} />
        <Route path="/contactus" element={<Layout><ContactUsForm /></Layout>} />
        <Route path="/products" element={<Layout><Products /></Layout>} />
        <Route path="/store" element={<Layout><Store /></Layout>} />

        <Route path="/products/1.-silicon-wafers" element={<Layout><SiliconWafers /></Layout>} />
        <Route path="/products/2.-fused-zone-silica-wafers" element={<Layout><FusedZoneSilicaWafers /></Layout>} />
        <Route path="/products/3.-silicon-on-insulator-wafers" element={<Layout><SiliconOnInsulatorWafers /></Layout>} />
        <Route path="/products/4.-silicon-thermal-oxide-wafers" element={<Layout><SiliconThermalOxideWafers /></Layout>} />
        <Route path="/products/5.-silicon-nitride-wafers" element={<Layout><SiliconNitrideWafers /></Layout>} />
        <Route path="/products/6.-fused-silica-wafers" element={<Layout><FusedSilicaWafers /></Layout>} />
        <Route path="/products/7.-borofloat-33-glass-wafers" element={<Layout><Borofloat33GlassWafers /></Layout>} />
        <Route path="/products/8.-semiconductor-materials" element={<Layout><SemiconductorMaterials /></Layout>} />
        
        <Route path="/main1" element={<Main1 />} />
      </Routes>
    </Router>
  );
}

export default App;
