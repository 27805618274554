// AboutUs.jsx

import React from 'react';
import AboutUsComenTopBanner from '../components-templates/AboutUs/AboutUsComenTopBanner';
import About from '../components-templates/AboutUs/About';
import HowWeDoIt from '../components-templates/AboutUs/HowWeDoIt';
import MapComponent from '../components-templates/AboutUs/MapComponent';
//import '../styles/AboutUs/AboutUs.scss';

const AboutUs = () => {
  return (
    <>
      <div className="AboutUs">
        <AboutUsComenTopBanner />
        <About />
        <HowWeDoIt />
        <MapComponent />
      </div>
    </>
  );
};

export default AboutUs;
