// SEMIStandardSpecifications.jsx

import React from 'react';
import '../../../../../styles/Products/ProductsPages/Common/SEMIStandardSpecifications.scss';

const SEMIStandardSpecifications = () => {
  return (
    <section id="SEMI-standard-specifications-section" className="SEMI-standard-specifications-section">
      <div className="SEMI-standard-specifications">
        <div className="SEMI-standard-specifications-title">
          <h1>SEMI Standard Specifications</h1>
        </div>
        <div className="SEMI-standard-specifications-div">
          <table className="SEMI-standard-specifications-table">
            <thead>
              <tr>
                <th>Specifications</th>
                <th>2” (50.8mm)</th>
                <th>3” (76.2mm)</th>
                <th>4” (100mm)</th>
                <th>5” (125mm)</th>
                <th>6” (150mm)</th>
                <th>8” (200mm)</th>
                <th>12” (300mm)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Diameter</td>
                <td>50.8 ± 0.38mm</td>
                <td>76.2 ± 0.63mm</td>
                <td>100 ± 0.5mm</td>
                <td>125 ± 0.5mm</td>
                <td>150 ± 0.2mm</td>
                <td>200 ± 0.2mm</td>
                <td>300 ± 0.2mm</td>
              </tr>
              <tr>
                <td>Thickness</td>
                <td>279 ± 25µm</td>
                <td>381 ± 25µm</td>
                <td>525 ± 20 µm or 625 ± 20µm</td>
                <td>625 ± 20µm</td>
                <td>675 ± 20µm or 625 ± 15µm</td>
                <td>725 ± 20µm</td>
                <td>775 ± 20µm</td>
              </tr>
              <tr>
                <td>Primary Flat Length</td>
                <td>15.88 ± 1.65mm</td>
                <td>22.22 ± 3.17mm</td>
                <td>32.5 ± 2.5mm</td>
                <td>42.5 ± 2.5mm</td>
                <td>57.5 ± 2.5mm</td>
                <td>Notch</td>
                <td>Notch</td>
              </tr>
              <tr>
                <td>Secondary Flat Length</td>
                <td>8 ± 1.65mm</td>
                <td>11.18 ± 1.52mm</td>
                <td>18 ± 2.0mm</td>
                <td>27.5 ± 2.5mm</td>
                <td>37.5 ± 2.5mm</td>
                <td>NA</td>
                <td>NA</td>
              </tr>
              <tr>
                <td>Primary Flat/Notch Location</td>
                <td>(110) ± 1°</td>
                <td>(110) ± 1°</td>
                <td>(110) ± 1°</td>
                <td>(110) ± 1°</td>
                <td>(110) ± 1°</td>
                <td>(110) ± 1°</td>
                <td>(110) ± 1°</td>
              </tr>
            </tbody>
          </table>
        </div>
          <p className="specification-note">
            Specifications for custom diameter wafers, thick wafers, thin wafers, DSP wafers, and other custom wafers are not strictly related to the SEMI M1-0302 protocols. Please contact us if you have any questions about SEMI standard specifications.
          </p>
      </div>
    </section>
  );
};

export default SEMIStandardSpecifications;