//Layout.jsx
import React from 'react';
import Topbar from './Topbar';
import Footer from './Footer';
import '../styles/Layout.scss';

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Topbar />
      <div className="layout-content">
        <main className="layout-main">
          {children}
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
