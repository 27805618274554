// BuildSiliconWafer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import quote_min from '../../images/BuildSiliconWafer/quote-min.svg';
import build_min from '../../images/BuildSiliconWafer/build-min.svg';
import deliver_min from '../../images/BuildSiliconWafer/deliver-min.svg';
import '../../styles/Home/BuildSiliconWafer.scss';

const BuildSiliconWafer = () => {
  return (
    <section className="build-sec cmn-gap">
      <div className="container">
        <div className="center-cont">
          <h2>Build Your Own Silicon Wafer</h2>
          <p>Silicon Phoenixsolution not only makes silicon wafers with SEMI standard, but also manufactures wafers with unique specification to meet customer needs.</p>
          <p>We provide custom diameter wafers, thick wafers, thin wafers, DSP wafers, and other custom wafers.</p>
        </div>
        <div className="build-card-row row">
          <div className="col-md-4 col-6 build-card-col">
            <div className="cmn-card-2">
              <div className="build-top-area">
                <div className="build-top-area-img">
                  <img
                    width="64"
                    height="65"
                    src={quote_min}
                    alt="Request a Quote"
                  />
                </div>
                <h3>Request a Quote</h3>
                <span className="build-class-no">1</span>
              </div>
              <p>Submit your specifications for a custom quote on our silicon wafers.</p>
            </div>
          </div>
          <div className="col-md-4 col-6 build-card-col">
            <div className="cmn-card-2">
              <div className="build-top-area">
                <div className="build-top-area-img">
                  <img
                    width="64"
                    height="65"
                    src={build_min}
                    alt="We Build"
                  />
                </div>
                <h3>We Build</h3>
                <span className="build-class-no">2</span>
              </div>
              <p>We manufacture your custom silicon wafers to your exact specifications.</p>
            </div>
          </div>
          <div className="col-md-4 col-6 build-card-col">
            <div className="cmn-card-2">
              <div className="build-top-area">
                <div className="build-top-area-img">
                  <img
                    width="72"
                    height="72"
                    src={deliver_min}
                    alt="We Deliver"
                  />
                </div>
                <h3>We Deliver</h3>
                <span className="build-class-no">3</span>
              </div>
              <p>Your finished custom silicon wafers are carefully packaged and shipped to you.</p>
            </div>
          </div>
        </div>
        <div className="btn-center">
          <div className="btn-center">
            <Link to="/quoterequest" onClick={() => window.scrollTo(0, 0)} >
              <button>Request a Quote</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuildSiliconWafer;
