// FusedSilicaWafersTopBanner.jsx

import React from 'react';
//import Logo from '../images/PHOENIXSOLUTION-1.png';
import '../../../../../styles/Products/ProductsComenTopBanner.scss';
import '../../../../../styles/Products/ProductsPages/WafersTopBanner.scss';
import ArrowWhite from '../../../../../images/Icon/btn-arrow-white.svg';

const FusedSilicaWafersTopBanner = () => {
    const scrollToproducts = () => {
        const aboutSection = document.getElementById('borofloat-silica-glass-wafers-section');
        if (aboutSection) {
          aboutSection.scrollIntoView({ behavior: 'smooth' });
        }
      };
    return (
        <>
            <section className="Comen-top-banner WafersTopBanner">
                <div className="container">
                    <div className="Comen-top-banner-background"></div>
                </div>
                <div className="Comen-top-banner-cont">
                    <h1>Borofloat 33 Glass Wafers</h1>
                    <span className="cursive-font">Phoenix Solutions</span>
                </div>
                <div className="scroll-down-arrow" onClick={scrollToproducts}>
                    {/* &#x25BC; Downward arrow symbol */}
                    <i>
                        <img src={ArrowWhite} alt="btn-arrow-white" />
                    </i>
                </div>
            </section>
        </>
    );
};

export default FusedSilicaWafersTopBanner;
