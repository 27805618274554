import React from 'react';
import '../styles/Home/Home.scss';
import Banner from '../components-templates/Home/Banner';
import Homeabout from '../components-templates/Home/Homeabout';
import WhatWeMake from '../components-templates/Home/WhatWeMake';
import BuildSiliconWafer from '../components-templates/Home/BuildSiliconWafer';
import ShopOnlineSec from '../components-templates/Home/ShopOnlineSec';
import WorkingWaferSec from '../components-templates/Home/WorkingWaperSec';
import YourWaferPartner from '../components-templates/Products/YourWaferPartner';
import WorldwideSec from '../components-templates/Home/WorldwideSec';

const Home = () => {
  return (
    <div className="home">
      <Banner />
      <Homeabout />
      <WhatWeMake />
      <BuildSiliconWafer />
      <ShopOnlineSec />
      <WorkingWaferSec />
      <YourWaferPartner />
      <WorldwideSec />
    </div>
  );
};

export default Home;
