// ContactUsForm.jsx

import React from 'react';
import ContactUsFormComenTopBanner from '../components-templates/ContactUsForm/ContactUsFormComenTopBanner';
import ContactSection from '../components-templates/ContactUsForm/ContactSection';
import ContactForm from '../components-templates/ContactUsForm/ContactForm';
import '../styles/ContactUsForm/ContactUsForm.scss';

const ContactUsForm = () => {
  return (
    <>
      <div className="ContactUsForm">
        <ContactUsFormComenTopBanner />
        <div id="ContactUsForm-section" className="ContactUsForm-section">
            <ContactSection />
            <ContactForm />
        </div>
      </div>
    </>
  );
};

export default ContactUsForm;