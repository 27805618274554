// ContactForm.jsx

import React from 'react';
import '../../styles/ContactUsForm/ContactForm.scss';

const ContactForm = () => {
  return (
    <div className="contact-form-container">
      <form className="contact-form">
        <div className="form-row">
          <div className="form-group small-form-group">
            <label htmlFor="firstName">First Name<span>*</span></label>
            <input type="text" id="firstName" placeholder="First Name" required/>
          </div>
          <div className="form-group small-form-group">
            <label htmlFor="lastName">Last Name<span>*</span></label>
            <input type="text" id="lastName" placeholder="Last Name" required />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="company">Company</label>
          <input type="text" id="company" placeholder="Company Name" />
        </div>
        <div className="form-row">
          <div className="form-group small-form-group">
            <label htmlFor="email">Email<span>*</span></label>
            <input type="email" id="email" placeholder="Email Address" required />
          </div>
          <div className="form-group small-form-group">
            <label htmlFor="phone">Phone</label>
            <input type="tel" id="phone" placeholder="Phone number" />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject<span>*</span></label>
          <input type="text" id="subject" placeholder="Subject" required />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message<span>*</span></label>
          <textarea id="message" placeholder="Comment Here" required />
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
}

export default ContactForm;
