// TypesOfSiliconWafer.jsx

// TypesOfSiliconWafer.jsx

import React, { useState, useEffect } from 'react';
import down from "../../../../../images/Icon/btn-arrow-black.svg"
import '../../../../../styles/Products/ProductsPages/Common/TypesOfSiliconWafer.scss';

const TypesOfSiliconWafer = () => {
  const [selectedWafer, setSelectedWafer] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 801);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const wafers = [
    { id: 1, name: 'Prime Silicon Wafer', description: 'Prime silicon wafers are also referred to as Device Grade or Particle Grade wafers. Prime wafers have tighter spec for Thickness, Bow, Warp, TTV, Surface Roughness, and Cleanliness.', applications: 'Applications: Semiconductor devices, Photolithography, Particle Monitors, and more.' },
    { id: 2, name: 'Test Silicon Wafer', description: 'Also referred to as Monitor or Dummy wafers. Test wafers have wider or looser specs than Prime wafers.', applications: 'Applications: Equipment Setup and Testing, Production Process Evaluation, and Process Development.' },
    { id: 3, name: 'Reclaimed Silicon Wafer', description: 'Reclaimed wafers are Prime and Test wafers that have been used in manufacturing and chemically/mechanically treated for reuse.', applications: 'Applications: Lower-cost alternative for manufacturing processes.' },
    { id: 4, name: 'Ultra Thin Silicon Wafer', description: 'Our ultra-thin silicon wafers range from 10-50 microns thick. These wafers are flexible and used in MEMS, advanced CMOS logic, and more.', applications: 'Applications: MEMS, advanced logic devices.' },
    { id: 5, name: 'Ultra Thick Silicon Wafer', description: 'With thickness up to 3mm thick, these are used for higher energy applications like power devices and sensors.', applications: 'Applications: Power devices, sensors.' },
    { id: 6, name: 'Diced Silicon Wafer', description: 'Standard silicon substrates precisely diced into smaller rectangular pieces.', applications: 'Applications: Prototypes, pilot production, low/medium volume manufacturing.' },
    { id: 7, name: 'Silicon Wafer With Thermal Oxide SiO2', description: 'Wafers with Thermal Oxide (SiO2) layers for MEMs and dielectric layers.', applications: 'Applications: MEMS devices, dielectric layers.' },
    { id: 8, name: 'Silicon Wafer With LPCVD or PECVD Nitride SiN', description: 'Wafers with LPCVD or PECVD Nitride layers for various temperature-sensitive processes.', applications: 'Applications: Various film deposition processes.' },
    { id: 9, name: 'Silicon Wafer with Metal Films', description: 'Silicon wafers with PVD or e-beam evaporated metal layers for interconnectivity.', applications: 'Applications: Device interconnects, metal films.' }
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 801);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (id) => {
    setSelectedWafer(id);
    if (isMobile) {
      setDropdownOpen(false); // Close the dropdown after selection on mobile
    }
  };

  return (
    <section id="types-of-silicon-wafer-section" className="types-of-silicon-wafer-section">
      <div className="types-of-silicon-wafer-container">
        <div className="types-of-silicon-wafer-background"></div>
      </div>
      <div className="types-of-silicon-wafer">
        <div className="types-of-silicon-wafer-title">
          <h1>Types Of Semiconductor Materials</h1>
        </div>
        <div className="types-of-silicon-wafer-div">
          
          {/* Dropdown or List depending on screen size */}
          {isMobile ? (
            <div className="types-of-silicon-wafer-dropdown">
              <button className="dropdown-toggle" onClick={() => setDropdownOpen(!dropdownOpen)}>
                {wafers.find(wafer => wafer.id === selectedWafer).name}
                <img src= {down}/>
              </button>
              {dropdownOpen && (
                <ul className="dropdown-menu">
                  {wafers.map(wafer => (
                    <li key={wafer.id} onClick={() => handleClick(wafer.id)}>
                      {wafer.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ) : (
            <div className="types-of-silicon-wafer-list">
              {wafers.map((wafer) => (
                <div
                  key={wafer.id}
                  className="block"
                  style={{
                    marginRight: selectedWafer === wafer.id ? '-15px' : '15px',
                  }}
                  onClick={() => handleClick(wafer.id)}
                >
                  <p>{wafer.name}</p>
                </div>
              ))}
            </div>
          )}

          <div className="types-of-silicon-wafer-text">
            {wafers.map((wafer) => (
              <div
                key={wafer.id}
                className="block"
                style={{
                  display: selectedWafer === wafer.id ? 'block' : 'none',
                }}
              >
                <h1>{wafer.name}</h1>
                <p>{wafer.description}</p>
                <p>{wafer.applications}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TypesOfSiliconWafer;