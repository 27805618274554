// OurCapability.jsx

import React from 'react';
import '../../../../../styles/Products/ProductsPages/Common/OurCapability.scss';

const OurCapability = () => {
  return (
    <section id="our-capability-section" className="our-capability-section">
      <div className="our-capability">
        <div className="our-capability-title">
          <h1>Our Capability</h1>
        </div>
        <div className="our-capability-div">
          <table className="capability-table">
            <thead>
              <tr>
                <th>Diameters</th>
                <th>2” (50.8mm)</th>
                <th>3” (76.2mm)</th>
                <th>4” (100mm)</th>
                <th>5” (125mm)</th>
                <th>6” (150mm)</th>
                <th>8” (200mm)</th>
                <th>12” (300mm)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Types</td>
                <td colSpan="7">P, N, Intrinsic</td>
              </tr>
              <tr>
                <td>Dopant</td>
                <td colSpan="7">Boron(B), Phosphorus(Ph), Antimony(Sb), Arsenic(As), Undoped</td>
              </tr>
              <tr>
                <td>Orientation</td>
                <td colSpan="7">(1-0-0), (1-1-1), (1-1-0), Custom</td>
              </tr>
              <tr>
                <td>Resistivity (ohm-cm)</td>
                <td colSpan="7">0.001 - 30,000+</td>
              </tr>
              <tr>
                <td>Thickness (um)</td>
                <td colSpan="7">20 - 2,000+</td>
              </tr>
              <tr>
                <td>Flats/Notch</td>
                <td colSpan="7">No Flat, SEMI Primary Flat, Jeida Flat, 2 SEMI Flats, Notch</td>
              </tr>
              <tr>
                <td>Finish</td>
                <td colSpan="7">SSP, DSP, Etched, Lapped</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default OurCapability;