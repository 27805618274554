// PoductsSection.jsx

import React, { useState } from 'react';
import Logo from '../../images/PHOENIXSOLUTION-1.png';
import '../../styles/Products/Products.scss';

const PoductsSection = () => {

  return (
    <>
      <section id="products-section" className="products-section who-we-are-sec cmn-gap">
        <div className="container">
          <div className="who-we-are-content">
            <div className="who-we-are-row row">
              <div className="who-we-are-col col-lg-7">
                <div className="who-we-are-img-area">
                  <img
                    width="641"
                    height="628"
                    src={Logo}
                    alt="who-we-are-image"
                  />
                  {/* <div className="vertical-txt">About Phoenix Solution</div> */}
                </div>
              </div>
              <div className="who-we-are-col col-lg-5">
                <div className="who-we-are-text-area">
                  <h2>Phoenix Solutions Product</h2>
                  <p>
                    Phoenix Solutions is a leading supplier of silicon wafers and semiconductor materials.
                    We offer Prime and Test silicon wafers that adhere to SEMI standards in a variety
                    of diameters from 2″ to 12″ (300mm). In addition to SEMI Standard silicon wafers,
                    we offer (FZ) float zone wafers, (SOI) silicon on insulator wafers, fused silica wafers,
                    borofloat 33 glass wafers, and other semiconductor materials. Double side polished, oxide,
                    MEMS wafers are also available.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PoductsSection;