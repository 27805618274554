// Products.jsx

import React from 'react';
import ProductsComenTopBanner from '../components-templates/Products/ProductsComenTopBanner';
import PoductsSection from '../components-templates/Products/PoductsSection';
import YourWaferPartner from '../components-templates/Products/YourWaferPartner';
import ProductsListWhatWeMake from '../components-templates/Products/ProductsListWhatWeMake';
import SiliconWaferSolutions from '../components-templates/Products/SiliconWaferSolutions'
//import '../styles/AboutUs/AboutUs.scss';

const Products = () => {
  return (
    <>
      <div className="products">
        <ProductsComenTopBanner />
        <PoductsSection />
        <YourWaferPartner />
        <ProductsListWhatWeMake />
        <SiliconWaferSolutions />
      </div>
    </>
  );
};

export default Products;