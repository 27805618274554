// WafersList.jsx

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'; // Removed unused useLocation
import '../../../../../styles/Products/ProductsListWhatWeMake.scss';
import '../../../../../styles/Products/ProductsPages/Common/WafersList.scss';

const WafersList = () => {
    const imageServerURL = process.env.REACT_APP_IMAGE_SERVER_URL;
    const BackendURL = process.env.REACT_APP_BACKEND_URL;
    
    const [products, setProducts] = useState([]);

    // Fetch products from the backend on component mount
    useEffect(() => {
        const fetchProducts = async () => {
            const url = `${BackendURL}/api/products`; // API URL

            try {
                const response = await fetch(url);
                const result = await response.json();
                setProducts(result);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    return (
        <section id="Silicon-Nitride-Wafers-List-section" className="wafers-List-section Silicon-Nitride-Wafers-List-section">
            <div className="wafers-list Silicon-Nitride-Wafers-List">
                <div className="wafers-List-title Silicon-Nitride-Wafers-List-title">
                    <h1>What we make</h1>
                    {/* <p>
                        WaferPro offers a comprehensive range of Czochralski, Float Zone, SOI, and Glass wafers,
                        ranging from non-polished to ultra-thin wafers.
                    </p> */}
                </div>
                <div className="container">
                    <div className="card-container">
                        {products.length > 0 ? (
                            products.map((product) => {
                                const productKey = encodeURIComponent(product.name.replace(/\s+/g, '-').toLowerCase());
                                return (
                                <div className="card-article" key={productKey}>
                                    <img src={`${imageServerURL}${product.image}`} alt={product.name} />
                                    <div className="card-data">
                                        <h3 className="card-title">{product.name}</h3>
                                        <p className="card-description">{product.description}</p>
                                        <div className="card-price">
                                            <span className="card-Price-amount amount">
                                                ${product.min_prize}
                                            </span>
                                            &nbsp; – &nbsp;
                                            <span className="card-Price-amount amount">
                                                ${product.max_prize}
                                            </span>
                                        </div>
                                        <NavLink to={`/products/${productKey}`} className="learn-more" onClick={() => window.scrollTo(0, 0)} >
                                            Learn more <i className="fa-solid fa-arrow-up"></i>
                                        </NavLink>
                                    </div>
                                </div>
                            )})
                        ) : (
                            <p>No products available.</p>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WafersList;