// Store.jsx

import React from 'react';
import StoreComenTopBanner from '../components-templates/Store/StoreComenTopBanner';
import StoreSection from '../components-templates/Store/StoreSection';
//import '../styles/AboutUs/AboutUs.scss';

const Store = () => {
  return (
    <>
      <div className="store">
        <StoreComenTopBanner />
        <StoreSection />
      </div>
    </>
  );
};

export default Store;