import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../images/PHOENIXSOLUTION-1.png';
import Visa from '../images/visa.png.webp';
import MasterCard from '../images/master.png.webp'; 
import PayPal from '../images/paypal.png.webp'; 
import Amex from '../images/amex.png.webp'; 
import '../styles/Footer.scss';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome

const Footer = ({ isContactUsVisible }) => {
  //console.log("Footer_isContactUsVisible = ",isContactUsVisible);
  return (
    <div className="footer">
      <div className="footer-mx" style={{ marginTop: isContactUsVisible ? '7rem' : '0rem' }}>

        <div className="footer-content">
          <div className="footer-logo">
            <img src={Logo} alt="Company Logo" />
          </div>
          <div className="quick-links">
            <h3>Quick Links</h3>
            <ul>
              <li><NavLink to="/" onClick={() => window.scrollTo(0, 0)} >Home</NavLink></li>
              {/* <li><NavLink to="/shipping-returns" onClick={() => window.scrollTo(0, 0)} >Shipping & Returns</NavLink></li>
              <li><NavLink to="/terms-conditions" onClick={() => window.scrollTo(0, 0)} >Terms & Conditions</NavLink></li>
              <li><NavLink to="/privacy-statement" onClick={() => window.scrollTo(0, 0)} >Privacy Statement</NavLink></li> */}
              <li><NavLink to="/about" onClick={() => window.scrollTo(0, 0)} >About</NavLink></li>
              <li><NavLink to="/quoterequest" onClick={() => window.scrollTo(0, 0)} >Quote Request</NavLink></li>
              <li><NavLink to="/contactus" onClick={() => window.scrollTo(0, 0)} >Contact Us</NavLink></li>
              <li><NavLink to="/products" onClick={() => window.scrollTo(0, 0)} >Products</NavLink></li>
              <li><NavLink to="/store" onClick={() => window.scrollTo(0, 0)} >Store</NavLink></li>
              <li><NavLink to="/contactus" onClick={() => window.scrollTo(0, 0)} >Contact Us</NavLink></li>
            </ul>
          </div>

          <div className="products">
            <h3>Products</h3>
            <ul>
              <li><a href="/products/1.-silicon-wafers" onClick={() => window.scrollTo(0, 0)} >Silicon Wafers</a></li>
              <li><NavLink to="/products/2.-fused-zone-silica-wafers" onClick={() => window.scrollTo(0, 0)} >Float Zone Silicon Wafers</NavLink></li>
              <li><NavLink to="/products/3.-silicon-on-insulator-wafers" onClick={() => window.scrollTo(0, 0)} >SOI Wafers</NavLink></li>
              <li><NavLink to="/products/4.-silicon-thermal-oxide-wafers" onClick={() => window.scrollTo(0, 0)} >Thermal Oxide Wafers</NavLink></li>
              <li><NavLink to="/products/5.-silicon-nitride-wafers" onClick={() => window.scrollTo(0, 0)} >Nitride Wafers</NavLink></li>
              <li><NavLink to="/products/6.-fused-silica-wafers" onClick={() => window.scrollTo(0, 0)} >Fused Silica Wafers</NavLink></li>
              <li><NavLink to="/products/7.-borofloat-33-glass-wafers" onClick={() => window.scrollTo(0, 0)} >Borofloat Glass Wafers</NavLink></li>
              <li><NavLink to="/products/8.-semiconductor-materials" onClick={() => window.scrollTo(0, 0)} >Other Materials</NavLink></li>
            </ul>
          </div>
          <div className="contact-info">
          <div>
            <h3>Contact Us</h3>
            <p><i className="fas fa-map-marker-alt"></i> 412 Martin Ave, Santa Clara, CA 95050</p>
            <p><i className="fas fa-envelope"></i> sales@yourdomain.com</p>
            <p><i className="fas fa-phone-alt"></i> (408) 622-9129</p>
            <p><i className="fas fa-clock"></i> Monday – Friday 9:00 a.m. to 6:00 p.m.</p>
          </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© Copyright <NavLink to="/">Silicon Phoenixsolution</NavLink> 2024</p>
          <div className="payment-icons">
            <img src={Visa} alt="Visa" />
            <img src={MasterCard} alt="MasterCard" />
            <img src={PayPal} alt="PayPal" />
            <img src={Amex} alt="Amex" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
