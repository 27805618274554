import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../styles/Home/Banner.scss';

const Banner = () => {
  const imageServerURL = process.env.REACT_APP_IMAGE_SERVER_URL;
  const BackendURL = process.env.REACT_APP_BACKEND_URL;
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    return () => {
      setBanners([]);
    };
  }, []);

  useEffect(() => {
    // console.log("Swiper Autoplay Configuration", {
    //   delay: 5000,
    //   disableOnInteraction: false
    // });
  }, []);


  useEffect(() => {
    const fetchBanners = async () => {
      const url = `${BackendURL}/api/allbanners`; // API URL
      try {
        const response = await fetch(url);
        const result = await response.json();

        // Check if result is an array
        if (Array.isArray(result)) {
          setBanners(result);
        } else {
          console.error('API returned data is not an array:', result);
        }
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, []);

  return (
    <section className="banner-area">
      <Swiper
        key={banners.length} // Force re-render when banners change
        modules={[Autoplay, Navigation, Pagination]}
        spaceBetween={30}
        slidesPerView={1} // Reduce slidesPerView
        slidesPerGroup={1} // Ensure slidesPerGroup is also reduced
        effect="fade"
        pagination={{ clickable: true }}
        loop={banners.length > 1}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
        className="banner-slider"
      >
        {banners.map((banner, index) => (
          <SwiperSlide key={index}>
            <div className="banner-slide">
              <div className="banner-img">
                <img
                  width="1989"
                  height="5000"
                  src={`${imageServerURL}${banner.image}`}
                  alt={banner.name}
                />
              </div>
              <div className="container">
                <div className="banner-cont">
                  <span className="p1">World Leading</span>
                  <h1>
                    <span className="banner-fixed-title">Supplier&nbsp;of </span>
                    <span className="banner-title">{banner.title}</span>
                  </h1>
                  <p>{banner.description}</p>
                    <div className="btn-center">
                    <Link to="/about">
                      <button> Learn More
                      </button>
                      </Link>
                    </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>


    </section>
  );
};

export default Banner;
