// HowWeDoIt.jsx

import React from 'react';
import { Link } from 'react-router-dom';
//import Logo from '../images/PHOENIXSOLUTION-1.png';
import '../../styles/AboutUs/HowWeDoIt.scss';

const HowWeDoIt = () => {
    return (
        <>
        <section className="how-we-do-it-area">
            <div className="how-we-do-it-area-container">
                <div className="how-we-do-it-area-background"></div>
            </div>
            <div className="container">
                <div className="how-we-txt cmn-gap">
                    <h2>How We Do It</h2>
                    <p>
                        Our products and services have widespread applications that are utilized across a diverse range of key industries today including semiconductors, optics, microelectromechanical systems (MEMS),
                        and solar technology manufacturing.
                    </p>
                    <p>
                        With Silicon Phoenixsolution’s exceptionally broad inventory catalogue, we are able to ship many standard 
                        <Link to="*" target="_blank" rel="noopener"> wafer types</Link> to you right away, often shipping completed orders to customers promptly.
                        Beyond our standard selections, we also have specialized capabilities to fulfill more unique, non-standard wafer orders through our made-to-order customization options.
                        These encompass tailoring various specific wafer characteristics such as differing diameters, thicknesses, 
                        <Link to="*" target="_blank" rel="noopener"> crystal orientations</Link>,
                        dopant levels, and layering – all customized precisely to each of our client’s individual application specifications and precise project requirements.
                    </p>
                    <p>
                        Additionally, by leveraging our scale, operational efficiencies, and optimized supply chain logistics, we are pleased to be able to offer our clients very competitively priced wafers in comparison
                        to alternatives in the marketplace. This pricing advantage combined with our rapid fulfillment and shipping times enables customers to complete their critical projects not just qualitatively,
                        but also cost-effectively while meeting budgetary needs, as well as quantitatively on schedule ensuring tight timelines can be met.
                    </p>
                    <p>
                        Supporting all of this, Silicon Phoenixsolution has a skilled team involved in every step of the process with intricate technical knowledge and years of industry experience under their belts.
                        Our dedicated professionals are deeply committed to providing exceptional customer service, comprehensive guidance, and expert technical advice to clients.
                        We highly value fostering long-term relationships, so we aim to ensure complete client satisfaction today and in every interaction moving forward.
                        Our teams truly engage with customers to grasp needs, provide solutions, and help with everything spanning instant technical troubleshooting to big picture market projections and technology roadmapping.
                        We want to enable our customers’ ongoing innovation along with their end market success – earning the opportunity to meet their emerging 
                        <Link to="*" target="_blank" rel="noopener"> wafer</Link> needs for tomorrow.
                    </p>
                </div>
            </div>
        </section>
        </>
    );
};

export default HowWeDoIt;
