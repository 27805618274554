// CustomSiliconWafer.jsx

import React from 'react';
import CustomSiliconWaferImg from '../../../../../images/Product/Silicon-Nitride-Wafers/Custom-Silicon-Wafer-Manufacturer-WaferPro.png.webp'
import '../../../../../styles/Products/ProductsPages/Common/CustomSiliconWafer.scss';

const OurCapability = () => {
    return (
        <section id="custom-silicon-wafer-section" className="custom-silicon-wafer-section">
            <div className="custom-silicon-wafer-container">
                <div className="custom-silicon-wafer-background"></div>
            </div>
            <div className="custom-silicon-wafer">
                <div className="custom-silicon-wafer-title">
                    <h1>Custom Silicon Nitride Wafers</h1>
                </div>
                <div className="custom-silicon-wafer-div">
                    <div className="image">
                        <img src={CustomSiliconWaferImg} />
                    </div>
                    <div className="text">
                        <p>
                            WaferPro prioritizes fulfilling your precise wafer specifications. We fabricate customized silicon wafers 
                            tailored to your individual project needs, with capabilities spanning:
                        </p>
                        <ul>
                            <li>Thin or thick profiles</li>
                            <li>Customized flat lengths</li>
                            <li>Special resistivity ranges</li>
                            <li>Ultra flat surfaces</li>
                            <li>Diverse film types (oxide, nitride, metals, film stacks, polysilicon, oxynitride)</li>
                        </ul>
                        <p>
                            Rather than taking a one-size-fits-all approach, we collaborate with you to understand and meet your 
                            specific requirements. Our customizable wafer manufacturing services empower organizations to bring their 
                            silicon-based innovations to life.
                        </p>
                        <p>
                            Contact our team today to discuss transforming your silicon wafer concepts into reality through our 
                            flexible design and production processes. We look forward to helping drive your next-generation devices 
                            and technologies.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurCapability;