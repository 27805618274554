// SiliconWaferSolutions.jsx

import React from 'react';
import '../../styles/Products/SiliconWaferSolutions.scss';

const SiliconWaferSolutions = () => {
    return (
        <>
            {/* SiliconWaferSolutions Us Section */}
            <section id="SiliconWaferSolutions-section" className="SiliconWaferSolutions">
                <div className="container">
                    <div className="SiliconWaferSolutions_container">
                        <h1>Wafer Solutions</h1>
                        <ul>
                            <li>Do you want a solution to your ongoing struggle to manage your silicon wafer supply?</li>

                            <li>WaferPro is here to help. Many of our customers enjoy our inventory management services, and you can too!</li>

                            <li>Blanket orders enable you to buy in high quantities while scheduling release dates based on your needs.</li>

                            <li>Consignment and Vendor-Managed Inventory free you from the day-to-day hassle of monitoring your supply, quoting and ordering new product, and staying on top of lead times. Just specify your min and max product levels, and let WaferPro do the rest. Contact your WaferPro Sales representative today, and see how our services can free you up to manage your business.</li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SiliconWaferSolutions;