// About.jsx

import React from 'react';
import Logo from '../../images/PHOENIXSOLUTION-1.png';
import '../../styles/AboutUs/AboutUs.scss';

const About = () => {
  return (
    <>
      {/* About Us Section */}
      <section id="about-section" className="who-we-are-sec about cmn-gap">
        <div className="container">
          <div className="who-we-are-content">
            <div className="who-we-are-row row">
              <div className="who-we-are-col col-lg-7">
                <div className="who-we-are-img-area">
                  <img
                    width="641"
                    height="628"
                    src={Logo}
                    alt="who-we-are-image"
                  />
                  {/* <div className="vertical-txt">About Phoenix Solution</div> */}
                </div>
              </div>
              <div className="who-we-are-col col-lg-5">
                <div className="who-we-are-text-area">
                  <h2>About Us</h2>
                  <p>
                    Phoenix Solution, established in 2016 and strategically situated in the heart of Silicon Valley, has swiftly become a foremost global purveyor of
                    silicon wafers and cutting-edge semiconductor materials. Providing service to an extremely diverse and demanding customer base
                    encompassing research laboratories to state-of-the-art high-volume manufacturing facilities, we are unwaveringly devoted to furnishing consistently topnotch products and superlative service
                    at very competitive costs. Leveraging our seasoned team’s expertise in materials science and engineering, innovative
                    production technologies, and responsive customer support, Phoenix Solution has constructed a stellar reputation as a top-tier supplier trusted by leading companies across the burgeoning semiconductor industry.
                    With an acute focus on product excellence, delivery reliability, and customer satisfaction, we persist in reinforcing valuable partnerships worldwide.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
