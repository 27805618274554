// AboutUsComenTopBanner.jsx

import React from 'react';
import '../../styles/ComenTopBanner.scss'
import '../../styles/AboutUs/AboutUsComenTopBanner.scss';
import ArrowWhite from '../../images/Icon/btn-arrow-white.svg';

const AboutUsComenTopBanner = () => {
  const scrollToAbout = () => {
    const aboutSection = document.getElementById('about-section');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <section className="Comen-top-banner AboutUsComenTopBanner">
        <div className="container">
          <div className="Comen-top-banner-background"></div>
        </div>
        <div className="Comen-top-banner-cont">
          <h1>THE STORY OF</h1>
          <span className="cursive-font">Phoenix Solutions</span>
        </div>
        <div className="scroll-down-arrow" onClick={scrollToAbout}>
          {/* &#x25BC; Downward arrow symbol */}
          <i>
            <img src={ArrowWhite} alt="btn-arrow-white" />
          </i>
        </div>
      </section>
    </>
  );
};

export default AboutUsComenTopBanner;
