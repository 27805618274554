// MajorApplicationsOfSiliconWafers.jsx

import React, { useState, useEffect } from 'react';
import '../../../../../styles/Products/ProductsPages/Common/MajorApplicationsOfSiliconWafers.scss';

const MajorApplicationsOfSiliconWafers = () => {

  const MajorApplicationsOfSiliconWafers = [
    { id: 1, name: 'Integrated Circuits (ICs)', description: 'Silicon is the foundation for fabricating microprocessors, memory chips,microcontrollers, and other integrated circuits.'},
    { id: 2, name: 'Sensors', description: 'Silicon MEMS technology allows manufacturing accelerometers, gyroscopes, pressure sensors, chemical sensors.'},
    { id: 3, name: 'Photonics', description: 'Silicon is useful for certain photonic/optical components like modulators,photodetectors, and silicon photonics chips.'},
    { id: 4, name: 'Power Electronics', description: 'Specialty silicon wafers support fabrication of high voltage, high frequency power electronics found in electric vehicle drivetrain systems.'},
    { id: 5, name: 'Discrete Semiconductors', description: 'Silicon substrates are used to produce discrete diodes, transistors, thyristors, and rectifiers.'},
    { id: 6, name: 'Analog Circuits', description: 'Many linear/analog ICs for power management, amplifiers, data converters are built on silicon wafers.'},
    { id: 7, name: 'Microfluidics', description: 'Silicon micromachining techniques shape channels, pumps and valves for lab-on-a-chip diagnostic devices.'},
    { id: 8, name: 'Optoelectronics', description: 'Optical sensors and source components like LEDs and lasers depend on silicon materials and device fabrication.'},
    { id: 9, name: 'Photovoltaics', description: 'Crystalline silicon wafers are widely used in manufacturing solar cells/panels for renewable energy generation.'}
  ];

  return (
    <section id="major-applications-of-silicon-wafers-section" className="major-applications-of-silicon-wafers-section">
      <div className="major-applications-of-silicon-wafers">
        <div className="major-applications-of-silicon-wafers-title">
          <h1>Major Applications Of Silicon Wafers</h1>
        </div>
        <div className="major-applications-of-silicon-wafers-div">
            {MajorApplicationsOfSiliconWafers.map((wafers) => (
              <div
                key={wafers.id}
                className="block"
              >
                <h1>{wafers.name}</h1>
                <p>{wafers.description}</p>
                <p>{wafers.applications}</p>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default MajorApplicationsOfSiliconWafers;