// QuoteRequestForm.jsx

import React from 'react';
//import Logo from '../images/PHOENIXSOLUTION-1.png';
import '../styles/QuoteRequestForm/QuoteRequestForm.scss';
import QuoteRequestFormComenTopBanner from '../components-templates/QuoteRequestForm/QuoteRequestFormComenTopBanner';
import QuoteRequestFormSection from '../components-templates/QuoteRequestForm/QuoteRequestFormSection';

const QuoteRequestForm = () => {
  return (
    <>
      <div className="QuoteRequestForm">
        <QuoteRequestFormComenTopBanner />
        <QuoteRequestFormSection />
      </div>
    </>
  );
};

export default QuoteRequestForm;
