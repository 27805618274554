// WafersSection.jsx

import React, { useState } from 'react';
import Logo from '../../../../../images/Product/1_WaferPro-LPCVD-PECVD-Silicon-Nitride-Wafer-2.png.webp';
import '../../../../../styles/Products/ProductsPages/Common/WafersSection.scss';

const WafersSection = () => {

  return (
    <>
      <section id="silicon-nitride-wafers-section" className="products fused-silica-wafers-section who-we-are-sec cmn-gap">
        <div className="container">
          <div className="who-we-are-content">
            <div className="who-we-are-row row">
              <div className="who-we-are-col col-lg-7">
                <div className="who-we-are-img-area">
                  <img
                    width="641"
                    height="628"
                    src={Logo}
                    alt="who-we-are-image"
                  />
                  {/* <div className="vertical-txt">About Phoenix Solution</div> */}
                </div>
              </div>
              <div className="who-we-are-col col-lg-5">
                <div className="who-we-are-text-area">
                  <h2>Silicon Nitride Wafers</h2>
                  <p>
                  Silicon wafers serve as the foundation for a variety of applications, especially in electronics manufacturing. 
                  As the building blocks of modern technology, they provide the substrate onto which circuits are constructed.
                  </p>
                  <p>
                    WaferPro offers prime silicon wafers meeting SEMI standards, along with customized options. 
                    Available in diameters ranging from 2 inch to 300mm, our wafers stem from monocrystalline Czochralski ingots.
                  </p>
                  <p>
                    We stock a wide selection of prime SEMI standard CZ wafers, ready to ship within 24 hours of ordering. 
                    Even custom orders with specialty films typically ship within three to five weeks. 
                    With both standard and customized wafers on hand, WaferPro delivers the high-quality silicon essential to 
                    tech manufacturers and researchers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WafersSection;